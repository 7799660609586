@use '../../styles/_normalize.css';
@use '../../styles/fonts';
@use '../../styles/icons';
@use '../../styles/svg';
@use '../../styles/mixins';
@use '../../styles/visibility';
@use '../../styles/global-variables' as vars;
@use '../../styles/breakpoints';
@use '../../styles/forms';
@use '../../styles/colors';
@use '../../styles/labels';
@use '../../styles/export';
@use '../../styles/legenda';
@use '../../styles/ng2-toastr';
@import '@angular/cdk/overlay-prebuilt.css';

body {
	@include mixins.default-font;
	overflow-x: hidden;

	background-color: vars.$color-cl-background-1;
	overflow-anchor: none;

	.mask-overlay {
		cursor: pointer;
	}

	&.no-scroll {
		position: fixed;

		width: 100%;
		overflow-y: scroll;
	}

	&.disable-vertical-scrolling {
		overflow: hidden;
		// is nodig zodat Safari op iOS de pagina op de achtergrond niet laat scrollen
		-webkit-overflow-scrolling: touch;
	}
}

.hidden {
	visibility: hidden;
}

.no-display {
	display: none !important;
}

.visible {
	visibility: visible;
}

.cdk-overlay-pane:has(.tooltip-element) {
	pointer-events: none;
}

/** Drag & Drop Styling **/
.dragdrop-placeholder {
	height: 40px;

	background-color: vars.$accent-positive-3;
	border: vars.$border-positive;
}

.cdk-drag-preview {
	box-sizing: border-box;
}

.cdk-drop-list-dragging .cdk-drag {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-disabled {
	cursor: auto;
}

.icon-drag {
	cursor: grab;
}

/** ====== **/

.subtext {
	color: vars.$color-cl-typography-2;
	font-size: 10pt;
}

a {
	color: vars.$color-cl-primary-1;
	font-weight: 600;
	text-decoration: none;

	outline: 0;
	cursor: pointer;

	&:hover {
		color: vars.$color-cl-secondary-1;
	}
}

// default styling from foundation
p {
	margin-top: 1em;
	margin-bottom: 1rem;
}

.p-not-padded p {
	margin-top: initial;
	margin-bottom: initial;
}

.line-clamp-2 {
	/* stylelint-disable-next-line value-no-vendor-prefix*/
	display: -webkit-box;
	overflow: hidden;

	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	/* stylelint-disable-next-line property-no-vendor-prefix*/
	-webkit-box-orient: vertical;
}

.pointer {
	cursor: pointer;
}

.text-ellipsis {
	@include mixins.text-ellipsis;
}

@include breakpoints.breakpoint(tablet-portrait) {
	body.disable-vertical-scrolling {
		position: initial;

		max-height: initial;
	}
}

body .mat-dialog-container {
	padding: 0;
}

h2 {
	font-weight: normal;
	font-size: 1.25rem;
	line-height: 1.625rem;
}
h3 {
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.375rem;
}
h4 {
	font-size: inherit;
	font-weight: 600;
}

.toets-cell {
	padding-left: 0.1rem !important;

	&.cdk-footer-cell,
	&.cdk-cell {
		border-left: 1px solid vars.$color-cl-typography-5;
	}

	table {
		border-collapse: collapse;
	}
	tr {
		display: flex;

		td {
			width: calc(2.7rem + 2px);
			margin-left: calc(0.1rem - 2px);

			&:not(:last-child) {
				margin-right: calc(0.1rem + 0.25rem - 2px);
			}
		}
	}
}

// Shades op alternerende oneven rijen worden 1 tint donkerder
// alternateGroup staat dubbel om specifieker te zijn
tr.cdk-row.alternating:nth-child(odd),
tr.cdk-row.alternateGroup.alternateGroup {
	.barchart .shades .shade {
		&.light {
			background: vars.$cumlaude-grijs;
		}
		&.dark {
			background: vars.$cumlaude-donkergrijs;
		}
	}
}

// In dashboards waarin bars tegen elkaar aan staan die mogelijk dezelfde kleur hebben
// (zoals Doorstroom), krijgen de bars een stippellijn ertussen.
.dashboard-doorstroom,
.dashboard-plaatsing-advies {
	div.bar:not(.gutter) + div + div.bar:not(.gutter) {
		border-left: 1px dotted rgba(0, 0, 0, 0.5);
	}
}

html,
body {
	height: 100%;
}

.groepering {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding-right: 1.5rem;

	.weergave {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		&.dossier {
			dt-form-dropdown {
				width: 10rem;
			}
		}

		&.cijfertype {
			dt-form-dropdown {
				width: 8.5rem;
			}
		}

		&.cijfersseceweergave {
			dt-form-dropdown {
				width: 10rem;
			}
		}

		&.doorstroomweergave {
			dt-form-dropdown {
				width: 10rem;
			}
		}

		&.eenheid {
			dt-form-dropdown {
				width: 9rem;
			}
		}

		&.adviestype {
			dt-form-dropdown {
				width: 15rem;
			}
		}

		&.cohortrendementweergave {
			dt-form-dropdown {
				width: 11rem;
			}
		}

		&.cohortrendementtype {
			dt-form-dropdown {
				width: 14rem;
			}
		}

		&.interval {
			dt-form-dropdown {
				width: 8rem;
			}
		}

		&.tijdseenheid {
			dt-form-dropdown {
				width: 13rem;
			}
		}

		&.kenmerk {
			dt-form-dropdown {
				width: 11rem;
			}
		}
	}

	.right {
		margin-left: auto;
	}

	.legenda:not(.right) {
		margin-left: unset;
	}
}
